<template>
  <section v-if="testimonials || reviews.length" class="bg-neutral-10">
    <div class="container py-32 md:py-56 lg:py-88">
      <h2 class="text-h-xl md:text-h-2xl font-semibold pb-16 md:pb-32">
        {{ testimonials?.title }}
      </h2>
      <div class="flex flex-wrap -mx-16">
        <div class="px-16 w-full lg:w-2/3 mb-16 lg:mb-0">
          <div class="bg-primary-10 rounded-12 md:rounded-24 p-16 md:p-48 flex justify-between h-full">
            <div>
              <se-svg name="quotes" :height="36" :width="48" class="hidden md:block md:visible text-primary-140" />
              <se-svg name="quotes" :height="18" :width="24" class="block md:hidden min-w-24 text-primary-140" />
            </div>
            <div class="text-h-m md:text-h-2xl font-semibold px-16 md:px-0 md:py-32 text-neutral-80 self-center">
              {{ reviews?.length ? reviews[0]?.feedback : testimonials?.mainTestimonial }}
            </div>
            <div class="self-end">
              <se-svg name="quotes" :height="36" :width="48" class="transform !rotate-180 hidden md:block md:visible text-primary-140" />
              <se-svg name="quotes" :height="18" :width="24" class="transform !rotate-180 block md:hidden min-w-24 text-primary-140" />
            </div>
          </div>
        </div>
        <div class="px-16 w-full lg:w-1/3 flex flex-col md:flex-row lg:flex-col gap-16">
          <template v-if="reviews?.slice(1).length">
            <se-card v-for="(review, ti) of reviews?.slice(1)" :key="ti" class="p-16">
              <div class="flex justify-between pb-16">
                <div class="flex">
                  <se-avatar :size="48" :name="review.client.name" :src="review.client.avatar" />
                  <div class="pl-16">
                    <h6 class="text-h-l font-semibold pb-8 leading-20 ">
                      {{ review.client.name }}
                    </h6>
                    <div class="flex items-center !gap-2">
                      <se-svg v-for="i of review.feedback_rate" :key="i" name="star-filled" :height="16" :width="16" class="text-warning-120" />
                    </div>
                  </div>
                </div>
                <div class="inline-block md:hidden lg:inline-block">
                  <p class="text-b-s text-neutral-50">
                    <!-- {{ review.created_at }} -->
                  </p>
                </div>
              </div>
              <div>
                <p class="text-b-s text-neutral-80">
                  {{ review.feedback }}
                </p>
              </div>
            </se-card>
          </template>
          <template v-else>
            <se-card v-for="(review, ti) of testimonials?.testimonials" :key="ti" class="p-16">
              <div class="flex justify-between pb-16">
                <div class="flex">
                  <se-avatar :size="48" :name="review.name" />
                  <div class="pl-16">
                    <h6 class="text-h-l font-semibold pb-8 leading-20 ">
                      {{ review.name }}
                    </h6>
                    <div class="flex items-center !gap-2">
                      <se-svg v-for="i of 5" :key="i" name="star-filled" :height="16" :width="16" class="text-warning-120" />
                    </div>
                  </div>
                </div>
                <div class="inline-block md:hidden lg:inline-block">
                  <!-- <p class="text-b-s text-neutral-50">
                    {{ review.date }}
                  </p> -->
                </div>
              </div>
              <div>
                <p class="text-b-s text-neutral-80">
                  {{ review.text }}
                </p>
              </div>
            </se-card>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import SeSvg from '@/src/shared/ui-kit/svg/Svg';
import SeAvatar from '@/src/shared/ui-kit/avatar/Avatar';
import SeCard from '@/src/shared/ui-kit/card/Card';
import { theme } from '@/theme';

const { testimonials } = theme.home;
const { reviews } = defineProps({
  reviews: {
    type: Array,
    required: true,
  },
});

</script>

<script lang="ts">
export default {
  name: 'SeStaticHomeTestimonials',
};
</script>
