<template>
  <section v-if="howToStart">
    <div class="container py-32 md:py-56 lg:py-88">
      <h2 class="text-h-xl md:text-h-2xl font-semibold pb-16 md:pb-32">
        {{ howToStart.title }}
      </h2>
      <div class="flex flex-wrap gap-y-16 -mx-16">
        <article v-for="(step, si) of howToStart.steps" :key="si" class="px-16 w-full md:w-1/2 lg:w-1/4">
          <div class="bg-neutral-10 p-16 rounded-12">
            <div class="w-40 h-40 rounded-full flex items-center justify-center text-h-l font-semibold mb-16" :class="theming[si] || ''">
              {{ si + 1 }}
            </div>
            <h6 class="text-h-l font-semibold pb-8">
              {{ step.title }}
            </h6>
            <p class="text-b-m">
              {{ step.description }}
            </p>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { theme } from '@/theme';

const { howToStart } = theme.home;

const theming: string[] = [
  'text-white bg-primary-140',
  'text-white bg-primary-100',
  'text-white bg-primary-40',
  'text-primary-140 bg-primary-20',
];
</script>

<script lang="ts">
export default {
  name: 'SeStaticHomeHowToStart',
};
</script>
